<template>
    <div class="empty">
        <!--        검색결과가 없습니다.-->
        {{emptyText()}}
        <button class="full_btn list_btn" @click="runResetFilter">목록</button>
    </div>
</template>

<script>
    import EventBus from "@/utils/event-bus";

    export default {
        name: "BoardEmptySearchLayout",
        inject: {
            BoardListHasFilter: {
                default: () => {},
            },
            resetFilter: {
                default: () => {}
            },
        },
        props: {
        },
        data() {
            return {
                noDate: false,
            }
        },
        computed: {},
        mounted() {

        },
        methods: {
            emptyText() {
                return this.BoardListHasFilter() ? '검색결과가 없습니다.' : '게시글이 없습니다.'
            },
            runResetFilter() {
                this.resetFilter();
                this.$nextTick(() => {
                    EventBus.$emit('resetFilterData')
                })
            },
        },
    }
</script>

<style scoped>

</style>